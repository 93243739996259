import { createApp } from 'vue';
import App from '/src/App.vue';
import API from '/src/api';
import router from '/src/routes/router';

createApp(App)
    .mixin({
        created() {
            this.$api = API;
        }
    })
    .use(router)
    .mount('#app');
