<template>
  <button @click="startAlgorithm()" v-if="!started" class="start-button">START 🚀</button>
  <div v-else>
    <img class="default-background-image" src="../assets/default-background-image.svg">
    <div class="video-div" id="videoDiv">
      <iframe ref="video" allow="fullscreen;autoplay" allowfullscreen height="100%"
              v-if="videoIsPlaying && videoURL"
              :src="videoURL ?? ''" width="100%"></iframe>
      <img class="video-placeholder" v-if="videoURL" src="../assets/video-placeholder.svg">
    </div>
  </div>
</template>

<script>

import {MAX_VIDEO_LENGTH, SUBMISSION_FILE_PATH} from '../config';

export default {
  name: 'videos',
  data() {
    return {
      started: false,
      videoIsPlaying: false,
      remainingTime: MAX_VIDEO_LENGTH,
      videoURL: null,
      submissions: {},
      isFetchingData: false,
      isGoingToPlay: false,
      shownVideos: JSON.parse(localStorage.getItem('shownVideos')) || [],
    }
  },
  mounted() {
    this.readSubmissions();
  },
  methods: {
    readSubmissions() {
      fetch(SUBMISSION_FILE_PATH)
          .then(response => response.text())
          .then(data => {
            const submissions = {};
            data.split('\n').forEach(line => {
              const [key, value] = line.split(',');
              if (!value) {
                return;
              }
              submissions[key.trim()] = value.trim();
            });
            this.submissions = submissions;
          })
          .catch(error => console.error('Error reading the CSV file:', error));
    },
    startAlgorithm() {
      this.started = true;
      setInterval(() => {
        if (this.videoIsPlaying === true) {
          return;
        }

        console.log('getting passings')
        this.getPassings().then(passings => {
          if (passings.length === 0) {
            return;
          }
          this.processPassings(passings);
        });

      }, 1000);
    },
    async getPassings() {
      try {
        const response = await this.$api.app.get('v1/web/webhook/passings');
        return response.data.data;
      } catch (error) {
        console.error('Error fetching passings:', error);
      }
    },
    processPassings(passings) {
      for (let passing of passings) {

        if (this.isPassingRecent(passing) || this.isPassingAlreadyShown(passing.start_number)) {
          console.log('Deze passing gaat niks tonen = ' + passing.start_number)
          break;
        }


        let videoURL = this.findVideoURLByStartNumber(passing.start_number);

        if (videoURL && !this.isGoingToPlay) {
          this.isGoingToPlay = true;
          setTimeout(() => {
            console.log('Deze passing gaat wel tonen = ' + passing.start_number)
            this.markPassingAsShown(passing.start_number);
            this.playVideo();
            this.videoURL = this.formatStreamableURL(videoURL);
          }, 6000);
          break;
        }
      }
    },
    isPassingAlreadyShown(startNumber) {
      return this.shownVideos.includes(startNumber);
    },
    markPassingAsShown(startNumber) {
      this.shownVideos.push(startNumber);
      localStorage.setItem('shownVideos', JSON.stringify(this.shownVideos));
    },
    formatStreamableURL(url) {
      const videoID = url.split('/').pop();
      return `https://streamable.com/e/${videoID}?autoplay=1&loop=0&nocontrols=1`;
    },
    isPassingRecent(passing) {
      return new Date(passing.time) > new Date(new Date().getTime() - MAX_VIDEO_LENGTH * 1000);
    },
    findVideoURLByStartNumber(startNumber) {
      return this.submissions[startNumber] ?? null;
    },
    playVideo() {
      this.videoIsPlaying = true;
      this.remainingTime = MAX_VIDEO_LENGTH;

      setTimeout(() => {
        this.videoIsPlaying = false;
        this.videoURL = null;
        console.log('Video url uitgezet!!')
        this.isGoingToPlay = false;
      }, (MAX_VIDEO_LENGTH + 2) * 1000);
    },
  }
}
</script>
