<template>
  <div class="start" v-if="!started">
    <div class="start-form">
      <label>Auth key</label>
      <input v-model="authorizationKey" type="text" placeholder="Place the auth key here">
    </div>
    <button @click="readSubmissions()" class="start-button">START 🚀</button>
  </div>
  <div v-else-if="authorization === true">
    <div class="photo-logo">
      <img src="@/assets/logos/beneva.svg" alt="Beneva logo">
    </div>
    <div class="photo-grid">
        <div class="div1" v-if="activePhotos[1]" :class="{'no-image': !activePhotos[1].image}">
          <img :src="activePhotos[1].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[1].message }}</p>
              <p>- {{ activePhotos[1].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
        <div class="div2" v-if="activePhotos[2]" :class="{'no-image': !activePhotos[2].image}">
          <img :src="activePhotos[2].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[2].message }}</p>
              <p>- {{ activePhotos[2].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
        <div class="div3" v-if="activePhotos[3]" :class="{'no-image': !activePhotos[3].image}">
          <img :src="activePhotos[3].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[3].message }}</p>
              <p>- {{ activePhotos[3].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
        <div class="div4" v-if="activePhotos[4]" :class="{'no-image': !activePhotos[4].image}">
          <img :src="activePhotos[4].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[4].message }}</p>
              <p>- {{ activePhotos[4].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
        <div class="div5" v-if="activePhotos[5]" :class="{'no-image': !activePhotos[5].image}">
          <img :src="activePhotos[5].image" alt="">
          <div class="image-content">
            <img class="top-right-logo" src="@/assets/logos/beneva.svg" alt="Beneva logo">
            <img class="top-left-logo" src="@/assets/logos/beneva-mississauga.svg" alt="Beneva logo">
            <div class="image-text">
              <p>{{ activePhotos[5].message }}</p>
              <p>- {{ activePhotos[5].name }}</p>
            </div>
          </div>
          <div class="timer-bar"></div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'photos',
  data() {
    return {
      started: false,
      authorization: false,
      authorizationKey: '',
      submissions: {},
      activePhotos: {},
      imageDuration: 9000,
      seenMessages: [],
      dev: false,
      urlParams: new URLSearchParams(window.location.search),
      fakeData: [
        {
          "start_number": 1242,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 454545,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 12,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 12345,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 123,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 777,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 123456,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 908,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 467,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 999,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 876543,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 987123,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 907,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 906,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 905,
          "time": "2023-02-01T12:59:59.208000Z"
        },
        {
          "start_number": 904,
          "time": "2023-02-01T12:59:59.208000Z"
        }
      ]
    }
  },
  beforeMount() {
    const key = localStorage.getItem("Auth key");
    if(key) {
      this.authorizationKey = key;
    }
  },
  mounted() {
    const imageDuration = this.urlParams.get('seconds');
    if (imageDuration) {
      this.imageDuration = imageDuration * 1000;
    }
  },
  methods: {
    processPassings(passings) {
      console.log(passings, 'All passings');
      for (const passing of passings) {

        if (this.isPassingAlreadyShown(passing.start_number)) {
          console.log(passing, 'This passing is already seen');
          break;
        }

        const data = this.findDataByStartNumber(passing.start_number);
        console.log(data, 'This passing must be shown, so save this data in var data');
        if (data) {
          console.log(data, 'There is data!');

          let delay = this.urlParams.get('delay') ? this.urlParams.get('delay') * 1000 : 0;

          console.log('Start delay time out');
          // Delay
          setTimeout(() => {
            console.log('Start delay time out is over');
            const emptyIndex = this.findOutdatedPhoto();
            console.log(emptyIndex, 'This is the empty index');
            if (emptyIndex !== -1) {
                  console.log(emptyIndex, 'Empty index is found, lets show the picture');
                  this.activePhotos[emptyIndex] = {
                    name: data.name,
                    message: data.message,
                    image: data.image,
                    isOutdated: false
                  };
                  setTimeout(() => {
                    console.log(this.activePhotos[emptyIndex], 'This active photo is outdated');
                    this.activePhotos[emptyIndex].isOutdated = true;
                  }, this.imageDuration);
              }
            }, delay);
          console.log(passing.start_number, 'This passing start number is seen');
          this.seenMessages.push(passing.start_number);

        } else {
          console.log(data, 'Wtf, there is no data anymore?');
        }
      }
    },
    isPassingAlreadyShown(startNumber) {
      return this.seenMessages.includes(startNumber);
    },
    findOutdatedPhoto() {
      for (let i = 1; i <= 5; i++) {
        if (!this.activePhotos[i] || this.activePhotos[i].isOutdated) {
          return i;
        }
      }
      return -1;
    },
    findDataByStartNumber(startNumber) {
      return this.submissions[startNumber] ?? null;
    },
    readSubmissions() {
      this.started = true;
      // ik geef atm een random getal mee, dit is omdat gravity forms blijkbaar soms caching heeft waardoor je met dezelfde url soms oude data terug krijgt..
      const randomInt = Math.floor(Math.random() * (1000 - 700 + 1)) + 2000;
      let getUrl = this.dev ? "test.json" : 'https://sportunity.nu/wp-json/gf/v2/forms/13/entries?paging[page_size]=' + randomInt;
      let config = {
        method: 'get',
        url: getUrl,
        headers: {
          'Authorization': 'Basic ' + this.authorizationKey,
          'Content-Type': 'application/json',
          'accept': 'application/json'
        }
      };

      axios.request(config)
          .then((response) => {
            localStorage.setItem("Auth key", this.authorizationKey);
            this.authorization = true;
            for (const entry of response.data.entries) {
              // If not appropriate image, this field (checkbox) is checked
              if (entry['27.2'] === "") {
                this.submissions[entry['17']] = {
                  name: entry['5'],
                  message: entry['22'],
                  image: entry['1'],
                };
              }
            }
            this.startAlgorithm();
          })
          .catch((error) => {
            if(error.response.data.code === "rest_forbidden") {
              alert('Wrong auth key');
            } else {
              alert('Something went wrong, please contact TRACX development team.');
            }
            location.reload();
          });
    },
    startAlgorithm() {
      setInterval(() => {
        this.getPassings().then(passings => {
          if (passings.length === 0) {
            return;
          }
          this.processPassings(passings);
        });
      }, 1000);
    },
    async getPassings() {
      try {
        if(!this.dev) {
          const response = await this.$api.app.get('v1/web/webhook/passings');
          return response.data.data;
        }

        return [this.fakeData[Math.floor(Math.random() * this.fakeData.length)]];
      } catch (error) {
        console.error('Error fetching passings:', error);
        alert('Something went wrong, please contact TRACX development team.');
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.photo-logo {
  width: 100%;

  img {
    display: block;
    margin: 40px auto;
    max-width: 350px;
    max-height: 72px;
  }
}

.photo-grid {
  width: calc(100% - 48px);
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 330px);
  gap: 24px;
  position: relative;

  * {
    box-sizing: border-box;
  }

  > div {
    border-radius: 12px;
    position: relative;
    overflow: hidden;

    > img {
      background-color: #222222;
    }

    .image-content {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.7315519957983193) 0, rgba(255, 255, 255, 0) 100%);
      }

      .image-text {
        position: absolute;
        bottom: 12px;
        left: 12px;

        p {
          margin: 0;
          font-weight: 700;
          color: white;
        }

        p:first-child {
          font-size: 34px;
          line-height: 42px;
          letter-spacing: -0.68px;
          margin-bottom: 16px;
        }

        p:last-child {
          font-size: 24px;
        }
      }

      > img {
        position: absolute;
        object-fit: contain;
      }

      .top-right-logo {
        right: 12px;
        width: 125px;
        top: 28px;
        height: 30px;
      }

      .top-left-logo {
        top: 12px;
        left: 12px;
        width: 140px;
        height: 67px;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .div1 {
    grid-area: 1 / 1 / 3 / 3;
    border-radius: 24px;

    .image-content {
      .image-text {
        bottom: 32px;
        left: 32px;

        p:first-child {
          font-size: 72px;
          line-height: 87px;
          letter-spacing: -1.44px;
        }

        p:last-child {
          font-size: 48px;
        }
      }

      .top-right-logo {
        top: 57px;
        right: 24px;
        width: 220px;
        height: 55px;
      }

      .top-left-logo {
        top: 24px;
        left: 24px;
        width: 250px;
        height: 120px;
      }
    }
  }

  .div2 {
    grid-area: 1 / 3 / 2 / 4;
  }

  .div3 {
    grid-area: 1 / 4 / 2 / 5;
  }

  .div4 {
    grid-area: 2 / 3 / 3 / 4;
  }

  .div5 {
    grid-area: 2 / 4 / 3 / 5;
  }
}

.no-image {
  > img {
    display: none;
  }

  .image-content::before {
    background: linear-gradient(180deg, rgba(36, 36, 36, 0.00) -9.83%, #242424 89.7%), #402D85 !important;
  }

  .image-text {
    p:first-child {
      margin-bottom: 80px !important;
      width: 300px;
      text-align: center;
    }
  }
}

.no-image.div1 {
  .image-text {
    p:first-child {
      width: 600px;
      margin-bottom: 140px !important;
    }
  }
}
</style>
