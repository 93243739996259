import { createRouter, createWebHistory } from 'vue-router';
import videos from '../views/videos';
import photos from '../views/photos';

const routes = [
    {
        path: '/',
        component: photos,
    },
    {
        path: '/videos',
        component: videos,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
